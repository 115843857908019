<template>
  <div class="container-fluid ml-10">
     <div>
      <Table_sensor />
    </div>
  </div>
</template>

<script>
import Table_sensor from "@/components/sensor/Table_sensor.vue";
export default {
    components:{Table_sensor}
};
</script>

<style></style>
