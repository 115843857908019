<template>
  <div class="row">
    <!-- <div class="col-md-11 col-xl-12">
      <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
        <div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
          
          <form
            class="
              form
              d-flex
              align-items-md-center
              flex-sm-row flex-column flex-grow-1 flex-sm-grow-0
            "
          >
          
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
               
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="searchID"
                @input="onchangeSiteId()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Name"
              />
            </div>
           
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="status"
                @input="onchangestatus()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Status"
              />
            </div>
          
            <span class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"></span>
            <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
              <span class="svg-icon svg-icon-lg">
                
                <inline-svg src="media/svg/icons/General/Search.svg" />
                
              </span>
              <input
                type="text"
                v-model="battery"
                @input="onchangeBattery()"
                class="form-control border-0 font-weight-bold pl-2"
                placeholder="Search Battery"
              />
            </div>
            <button
              type="submit"
              class="
                btn btn-dark
                font-weight-bold
                btn-hover-light-primary
                mt-3 mt-sm-0
                px-7
              "
            >
              Search
            </button>
          </form>
          
        </div>
       
        <div class="mt-4 my-md-0 mx-md-10">
          <a class="text-dark font-weight-bolder text-hover-primary"
            >Clear All</a
          >
        </div>
      
      </div>
    </div> -->
    <div class="col-md-11 col-xl-12">
      <div class="card card-custom mb-3">
        <div class="card-header" style="border-color: var(--bordercolorform)">
          <div class="card-title">
            <h3 class="card-label cardLabelTiket">Sensor Status</h3>
          </div>
          <div class="card-toolbar">
            <b-button
              variant="danger"
              @click.prevent.stop="showModalAdd = true"
            >
              <b-icon-plus></b-icon-plus> Add Sensor
            </b-button>
          </div>
        </div>

        <div
          class="row no-gutters"
          style="display: flex; justify-content: center !important"
        >
          <div style="width: 98%; margin-top: 21px">
            <div id="chart" style="min-height: 300px">
              <DxDataGrid
                :ref="my - data - grid"
                :data-source="itemsuser"
                key-expr="id"
                :show-borders="true"
                :word-wrap-enabled="false"
                :column-auto-width="false"
                :remote-operations="true"
                :column-hiding-enabled="false"
              >
                <DxSearchPanel :visible="true" />
                <DxFilterRow :visible="false" />
                <DxColumn data-field="id" caption="ID" :width="70" />
                <DxColumn
                  data-field="name"
                  caption="Nama Device"
                  sort-order="asc"
                />
                <DxColumn
                  data-field="status"
                  caption="Status"
                  :allow-filtering="false"
                  sort-order="asc"
                />
                <DxColumn
                  data-field="battery"
                  caption="Battery"
                  :allow-filtering="false"
                  sort-order="asc"
                />

                <DxColumn :width="100" type="buttons" caption="Aksi">
                  <DxButton icon="fa fa-trash" hint="Hapus" :onClick="hapus" />
                  <DxButton icon="fa fa-edit" hint="Edit" :onClick="iconEdit" />
                </DxColumn>
                <DxPaging :page-size="10" />
              </DxDataGrid>

              <!--   Tambah New User   -->
              <b-modal
                v-model="showModalAdd"
                hide-footer
                centered
                scrollable
                size="lg"
                id="add-new-modal"
              >
                <template #modal-header="{ close }" class="text-center">
                  <h3>Add Device</h3>
                </template>
                <b-form @submit.stop.prevent="onSubmit">
                  <b-form-group
                    id="example-input-group-1"
                    label-for="example-input-1"
                  >
                    <template v-slot:label>
                      Site ID<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      id="select-siteid"
                      v-model="$v.form.site_id.$model"
                      :options="optionssite"
                      :state="validateState('site_id')"
                      aria-describedby="input-role-live-feedback"
                    >
                    </b-form-select>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Site ID harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Nama Device"
                  >
                    <b-form-input
                      id="input-name"
                      name="input-name"
                      placeholder="Nama Device"
                      v-model="$v.form.name.$model"
                      :state="validateState('name')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Nama Device harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Status"
                  >
                    <b-form-select
                      id="select-siteid"
                      v-model="$v.form.status.$model"
                      :options="roles"
                      :state="validateState('status')"
                      aria-describedby="input-role-live-feedback"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Pilih Salah Satu --</b-form-select-option
                        >
                      </template>
                      <b-form-select-option value="ON">ON</b-form-select-option>
                      <b-form-select-option value="OFF"
                        >OFF</b-form-select-option
                      >
                    </b-form-select>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Status harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Battery"
                  >
                    <b-form-input
                      id="range-1"
                      v-model="$v.form.battery.$model"
                      type="range"
                      min="0"
                      max="100"
                    ></b-form-input>
                    <div class="mt-2">
                      Battery: {{ $v.form.battery.$model }} %
                    </div>
                  </b-form-group>

                  <hr />
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn btn-cancel mr-8"
                      type="button"
                      @click="onCancel()"
                    >
                      Kembali
                    </button>
                    <button type="submit" class="btn btn-add">Simpan</button>
                  </div>
                </b-form>
              </b-modal>

              <!--   Edit Existing User   -->
              <b-modal
                v-model="showModalEdit"
                hide-footer
                scrollable
                centered
                size="lg"
                id="edit-user-modal"
              >
                <template #modal-header="{ close }" class="text-center">
                  <h3>Edit Device</h3>
                </template>
                <b-form @submit.stop.prevent="onSubmitEdit">
                  <b-form-group
                    id="example-input-group-1"
                    label-for="example-input-1"
                  >
                    <template v-slot:label>
                      Site ID<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      id="select-siteid"
                      v-model="$v.formEdit.site_id.$model"
                      :options="optionssite"
                      :state="validateState('site_id')"
                      aria-describedby="input-role-live-feedback"
                    >
                    </b-form-select>

                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Site ID harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>

                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Nama Device"
                  >
                    <b-form-input
                      id="input-name"
                      name="input-name"
                      placeholder="Nama Device"
                      v-model="$v.formEdit.name.$model"
                      :state="validateState('name')"
                      aria-describedby="input-1-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Nama Device harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Status"
                  >
                    <b-form-select
                      id="select-siteid"
                      v-model="$v.formEdit.status.$model"
                      :options="roles"
                      :state="validateState('status')"
                      aria-describedby="input-role-live-feedback"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Pilih Salah Satu --</b-form-select-option
                        >
                      </template>
                      <b-form-select-option value="ON">ON</b-form-select-option>
                      <b-form-select-option value="OFF"
                        >OFF</b-form-select-option
                      >
                    </b-form-select>
                    <b-form-invalid-feedback id="input-1-live-feedback"
                      >Status harus diisi.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    id="example-input-group-3"
                    label-for="input-name"
                    label="Battery"
                  >
                    <b-form-input
                      id="range-1"
                      v-model="$v.formEdit.battery.$model"
                      type="range"
                      min="0"
                      max="100"
                    ></b-form-input>
                    <div class="mt-2">
                      Battery: {{ $v.formEdit.battery.$model }} %
                    </div>
                  </b-form-group>

                  <hr />
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn btn-cancel mr-8"
                      type="button"
                      @click="$bvModal.hide('edit-user-modal')"
                    >
                      Kembali
                    </button>
                    <button type="submit" class="btn btn-add">Simpan</button>
                  </div>
                </b-form>
              </b-modal>
            </div>
          </div>
        </div>
        <b-modal v-model="detail" size="lg" title="Detail Priority" centered>
          <div class="mt-2">
            <b-button variant="danger"> Export </b-button>
          </div>
          <div>
            <b-table hover :items="itemsdetail" :fields="fieldsdetail">
              <template class="text-center" #cell(actions)=""> </template>
            </b-table>
          </div>
          <!-- <pre>{{ infoModal.content }}</pre> -->
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxFilterRow,
  DxPaging,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import WebService from "../../core/services/api";
import EventBus from "../../core/services/common/EventBus";
// import EventBus from "../../../core/services/common/EventBus";

const dataGridRefKey = "my-data-grid";

export default {
  mixins: [validationMixin],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DxButton,
    DxSearchPanel,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxPaging
  },
  data() {
    return {
      form: {
        site_id: "",
        name: "",
        battery: "",
        status: ""
      },
      optionssite: [
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" }
      ],
      selectedsite: null,
      formEdit: {
        id: null,
        site_id: "",
        name: "",
        battery: "",
        status: ""
      },
      perPage: 10,
      battery2: 0,
      currentPage: 1,
      totalRows: 0,
      dataSource: [],
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      columns: ["id", "name", "icon"],
      file1: null,
      file2: null,
      showModalAdd: false,
      showModalEdit: false,
      searchID: "",
      status: "",
      battery: "",
      statuses: [
        {
          value: "true",
          text: "Aktif"
        },
        { value: "false", text: "Tidak Aktif" }
      ],
      infoModal: {
        id: "info-modal",
        title: "",
        content: ""
      },
      selected: null,
      optionsmodule: [
        { value: null, text: "Select One" },
        { value: "a", text: "Yes" },
        { value: "b", text: "No" },
        { value: "c", text: "--" },
        { value: "d", text: "--" }
      ],
      // array user
      itemsuser: [],
      roles: [],
      detail: false,
      fieldsuser: [
        {
          key: "no",
          label: "No.",
          class: "text-center"
        },
        {
          key: "code",
          label: "Code",
          sortable: true,
          class: "text-center"
        },
        {
          key: "name",
          label: "Nama Kota/Kab",
          sortable: true,
          class: "text-center"
        },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  validations: {
    form: {
      site_id: {
        required
      },
      name: {
        required
      },
      status: {
        required
      },
      battery: {
        minLength: maxLength(3),
        required
      }
    },
    formEdit: {
      site_id: {
        required
      },
      name: {
        required
      },
      status: {
        required
      },
      battery: {
        required
      }
    }
  },
  mounted() {
    this.getAlldata();
    this.getLocalsite();
  },
  computed: {
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  methods: {
    getAlldata() {
      return WebService.getAllDevice().then(
        res => {
          this.itemsuser = res.data.data;
          this.totalRows = res.data.totalCount;
        },
        error => {
          console.log(error);
          this.itemsuser = [];
          this.totalRows = 0;
          if (error.response && error.response.status === 401) {
            EventBus.dispatch("logout");
          }
        }
      );
    },
    onchangeSiteId() {
      const filter = "name";
      const keyword = this.searchID ? this.searchID : "";
      if (this.searchID !== "") {
        var filteredData = this.itemsuser.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getAlldata();
      }
    },
    onchangestatus() {
      const filter = "status";
      const keyword = this.status ? this.status : "";
      if (this.status !== "") {
        var filteredData = this.itemsuser.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getAlldata();
      }
    },
    onchangeBattery() {
      const filter = "battery";
      const keyword = this.battery ? this.battery : "";
      if (this.battery !== "") {
        var filteredData = this.itemsuser.filter(function(obj) {
          return obj[filter] === keyword;
        });
        this.itemsuser = filteredData;
      } else {
        this.getAlldata();
      }
    },
    getLocalsite() {
      WebService.getlocalsiteref().then(async res => {
        const roles = res.data.data;
        let selectedOptions;
        selectedOptions = [];
        roles.map(i => {
          return selectedOptions.push({
            text: i.location_name,
            value: i.id
          });
        });
        this.optionssite = selectedOptions;
      });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    getLabelCounter() {
      let from = (this.currentPage - 1) * this.perPage + 1;
      let dari =
        this.perPage > this.itemsuser.length
          ? this.currentPage * this.perPage -
            this.perPage +
            this.itemsuser.length
          : this.currentPage * this.perPage;
      return (
        "Shown results " +
        from +
        " - " +
        dari +
        " From " +
        this.totalRows +
        " Data"
      );
    },
    getNumberSequence(index) {
      return index + 1 + this.currentPage * this.perPage - this.perPage;
    },
    hapus(value) {
      const id = value.row.data.id;
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Yakin Data Dihapus?.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "OK",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.deleteDevice(id).then(data => {
            console.log(data);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Data berhasil Dihapus",
              showConfirmButton: false,
              timer: 1500
            });
            this.resetForm();
            this.getAlldata();
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    hideModal() {
      this.$refs["my-modaledit"].hide();
    },
    onCancel() {
      this.showModalAdd = false;
      this.resetForm();
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        battery: parseInt(this.form.battery),
        name: this.form.name,
        site_id: parseInt(this.form.site_id),
        status: this.form.status
      };

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.addDevice(data).then(data => {
            console.log(data);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Data berhasil ditambah",
              showConfirmButton: false,
              timer: 1500
            });
            this.getAlldata();
            this.resetForm();
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmitEdit() {
      this.$v.formEdit.$touch();
      if (this.$v.formEdit.$anyError) {
        return;
      }

      let id = this.formEdit.id;
      let data = {
        id: this.formEdit.id,
        battery: parseInt(this.formEdit.battery),
        name: this.formEdit.name,
        site_id: parseInt(this.formEdit.site_id),
        status: this.formEdit.status
      };

      console.log(data);

      Swal.fire({
        title: "Konfirmasi",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return WebService.editDevice(data, id).then(res => {
            console.log(res);
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Data berhasil dirubah",
              showConfirmButton: false,
              timer: 1500
            });
            this.resetFormEdit();
            this.getAlldata();
          });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    resetForm() {
      this.form = {
        id: null,
        status: "",
        name: "",
        site_id: "",
        battery: 0
      };
      this.$nextTick(() => {
        this.$bvModal.hide("add-new-modal");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        id: null,
        site_id: "",
        name: "",
        status: "",
        battery: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("edit-user-modal");
      });
    },
    iconEdit(e) {
      this.showModalEdit = true;
      const data = e.row.data;
      this.formEdit.id = data.id;
      this.formEdit.name = data.name;
      this.formEdit.site_id = data.site_id;
      this.formEdit.status = data.status;
      this.formEdit.battery = data.battery;
    }
  }
};
</script>

<style>
.btn-add {
  width: 144px;
  height: 44px;
  background: #c60b09;
  border-radius: 8px;
  color: #fffafa;
}
.btn-cancel {
  width: 144px;
  height: 44px;
  border: 1px solid #c60b09;
  box-sizing: border-box;
  border-radius: 8px;
  color: #c60b09;
}
.title-card-custom {
  font-weight: 600;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.005em;
}
.table-custom {
  border-radius: 15px 15px 15px 15px !important;
}

.thead-block {
  text-align: center;
  background: #d1eefa;
  color: #360302;
  border: none;
}

.thead-block tr th:first-child {
  border-top-left-radius: 15px;
}

.thead-block tr th:last-child {
  border-top-right-radius: 15px;
}

.table-content {
  background: #fafcff;
  text-align: center;
  border-radius: 0 0 15px 15px;
}
</style>
